import { autoinject } from "aurelia-framework";
import { UnitPlanReportService } from "services/UnitPlanReportService";
import { UnitReportViewModelDto } from "models/UnitReportViewModelDto";
import { ReportStatus } from "models/ReportStatus";
import { PlanReportHeaderData } from "resources/plan-report-header/PlanReportHeaderData";
import { Subscription, EventAggregator } from "aurelia-event-aggregator";
import { UnitReportUpdated } from "resources/plan-report-header/UnitReportUpdated";

@autoinject
export class UnitReportView {
    reportid: number;
    unitReport: UnitReportViewModelDto;
    isDownloadingList = false;
    isDownloadingDetail = false;
    subscription: Subscription;
    constructor(public service: UnitPlanReportService, public ea?: EventAggregator) {}

    activate(params: {reportid: number}) {
        this.reportid = params.reportid;
    }

    async attached() {
        this.subscription = this.ea.subscribe(UnitReportUpdated, _ => this.loadReport(1));
        await this.loadReport(1);
    }

    detached() {
        this.subscription.dispose();
    }

    SingleTimeLastPeriodValuesUpdate(lastPeriodValuesUpdateNeeded: number) {
        /*
        Calls `updateReportLastPeriodFromModel()` for a single time
        when the report status is `Report Not Submitted`.

        `updateReportLastPeriodFromModel()` automatically calls `loadReport()`
        which causes the circular calling.
        */
        if( window.localStorage && lastPeriodValuesUpdateNeeded === 1 && this.unitReport.reportStatus !== ReportStatus.Submitted ) {
            if( !window.localStorage.getItem('firstLoad') ) {
                this.service.updateReportLastPeriodFromModel(this.reportid);
                window.localStorage['firstLoad'] = true;
            }
            else {
                window.localStorage.removeItem('firstLoad');
            }
        };
    }

    async getUnitReport(lastPeriodValuesUpdateNeeded: number) {
        this.unitReport = await this.service.getReport(this.reportid);
        this.SingleTimeLastPeriodValuesUpdate(lastPeriodValuesUpdateNeeded);
    }

    async loadReport(lastPeriodValuesUpdateNeeded: number) {
        this.getUnitReport(lastPeriodValuesUpdateNeeded);
    }

    get headerData() {
        if(!this.unitReport) return undefined;
        return new PlanReportHeaderData("Unit Report", this.reportid, this.unitReport.description, this.unitReport.reportStatus, this.unitReport.organization, this.unitReport.reportingPeriod);
    }
}
